export default {
    methods: {
        loadData: function(data, callback) {
            this.$store.dispatch('getDoctorList', data)
             .then((rs) => {
                var list = rs.list || []
                var total = rs.total || 0
                callback && callback({list, total}, null)
            }).catch((rs) => {
                callback && callback(null, rs)
            })
        }
    }
}