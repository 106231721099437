<template>
    <div id="doctor-list">
        <div class="search-box-container">
            <div class="search-box">
                <div class="search-filter-c">
                    <span class="label">專科</span>
                    <a-dropdown :trigger="['click']">
                        <div class="filter-c-value" :data-value="categoryValue">{{categoryName || "科別"}}</div>
                        <a-menu slot="overlay">
                            <a-menu-item @click="onCategoryItemClick('', '')" key="-1">科別</a-menu-item>
                            <a-menu-divider />
                            <a-menu-item @click="onCategoryItemClick(category.codedesc, category.codeid)" :key="category.codeid" v-for="category in categorys" v-text="category.codedesc"></a-menu-item>
                        </a-menu>
                    </a-dropdown>
                </div>
                <div class="search-filter-a">
                    <span class="label">地區</span>
                    <div class="filter-a-values">
                        <a-dropdown :trigger="['click']">
                            <div class="filter-a-v1" :data-value="area1Value||''" v-text="area1Name"></div>
                            <a-menu slot="overlay">
                                <a-menu-item @click="onArea1ItemClick(null, null)" key="-1">選擇地區</a-menu-item>
                                <a-menu-divider />
                                <a-menu-item @click="onArea1ItemClick(area.codeid, area.codedesc)" :key="area.codeid" v-for="area in area1Datas" v-text="area.codedesc"></a-menu-item>
                            </a-menu>
                        </a-dropdown>
                        <a-dropdown :trigger="['click']">
                            <div class="filter-a-v2" :data-value="area2Value || ''" v-text="area2Name"></div>
                            <a-menu slot="overlay">
                                <a-menu-item @click="onArea2ItemClick(null, null)" key="-1">選擇地區</a-menu-item>
                                <a-menu-divider />
                                <a-menu-item @click="onArea2ItemClick(area.codeid, area.codedesc)" :key="area.codeid" v-for="area in area2Datas" v-text="area.codedesc"></a-menu-item>
                            </a-menu>
                        </a-dropdown>
                    </div>
                </div>
                <div class="search-filter-k">
                    <span class="label">醫師</span>
                    <input class="filter-k-value" placeholder="輸入醫師名稱" v-model="keyword" @keyup="onKeyEnter"/>
                </div>
                <div class="btn-search" @click="doSearch">搜索</div>
                <router-link to="/hospital/list" class="link-clinic">全部診所</router-link>
            </div>
        </div>
        <div class="doctor-container">
            <div class="doctor-wrapper">
                <ul class="doctor-filter-tags" v-if="categorys.length">
                    <li :class="{'doctor-filter-tag': true, current: filterType==0, 'filter-up': !! categorySort['0'] }" @click="changeFilter(0, 0)">綜合</li>
                    <!-- <li :class="{'doctor-filter-tag': true, current: filterType==1, 'filter-up': isAsc }" @click="changeFilter(1, 0)">預約</li> -->

                    <li v-for="category in categorys"
                        :key="category.codeid" 
                        v-text="category.codedesc" 
                        :class="{
                            'doctor-filter-tag': true, 
                            'current': filterType==3 && filterValue==category.codeid,
                            'filter-up': !! categorySort[`${category.codeid}`]
                        }"
                        @click="changeFilter(3, category.codeid)"
                        >
                    </li>
                </ul>
                <div class="doctor-list" :style="{'grid-template-rows': Array.from(new Array(Math.floor((dataList.length+2) / 3)).keys()).map((d)=>'80px').join(' ')}">
                    <div class="doctor-item" v-for="doctor in dataList" :key="doctor.globaluserid">
                       <router-link :to="'/doctor/' + doctor.globaluserid" :class="{'doctor-item-avatar': true, 'man': doctor.sex == 1}"></router-link>
                        <div class="col">
                            <div class="row">
                                <div class="doctor-item-name"><router-link :to="'/doctor/' + doctor.globaluserid" v-text="doctor.username"></router-link></div>
                                <div class="doctor-item-book" @click="onBooingBtnClick(doctor)">預約</div>
                            </div>
                            <div class="row address">
                                <div class="doctor-item-address-list">
                                    <router-link class="doctor-item-address" :to="`/doctor/list?AreaLv1=${area.arealevel1id}&AreaLv2=${area.arealevel2id}`" v-for="(area, i) in doctor.areaList" :key="i" v-text="area.areaname"></router-link>
                                </div>
                            </div>
                            <div :class="{'booking-count': true, 'show':  doctor.isdisplayorder == true}" v-text="`已約${doctor.totalordercount}次`"></div>
                        </div>
                    </div>
                    <split-page v-if="visible" :initData="{pageNo, pageSize, total}" :onPageChanged="onPageChanged"/>
                </div>
            </div>
        </div>
        <loading v-if="loading" :style="{'margin-top': categorys.length ? '200px': '125px'}"/>
        <v-booking-time :callback="onBookingCallback" v-if="isBooking" :doctor="this.bookingDoctor"/>
        <clinic-detail v-if="isClinicDetail" />
    </div>
</template>

<script>
    import doctor_list from '@/views/mixins/doctor_list'
    import Loading from '@/layouts/Loading.vue'
    import SplitPage from '@/layouts/SplitPage.vue'
    import ClinicDetail from '../clinic/ClinicDetail'
    import vBookingTime from '../../../layouts/BookingTime'
	export default {
        mixins: [ doctor_list ],
        components: {
            Loading,
            vBookingTime,
            ClinicDetail,
            SplitPage,
        },
        data(){
            return {
                loading: true,
                visible: false,
                pageNo: 1,
                pageSize: 21,
                total: 0,
                dataList: [],
                filterType: 0,
                filterValue: 0,
                
                categorySort: {},
                
                keyword: '',
                isClinicDetail: !! this.$route.query.ClinicID,
                categoryValue: '',
                categoryName: '',

                area1Value: null,
                area2Value: null,
                area1Name: '選擇地區',
                area2Name: '選擇地區',

                arealevel1id: null,
                arealevel2id: null,

                isBooking: false,
                bookingDoctor: null,
                style: '',
                categorys: this.$store.getters.askCategorys || [],
                area1Datas: [],
                area2Datas: [],

                isSearch: false,

                bookingCount: null,
            }
        },
        mounted: function(){
            if(! this.area1Datas.length) {
                //获取地区
                this.$store.dispatch('getAreaList', {'langcode': 'B5'}).then((data)=>{
                    this.area1Datas = data
                })
            }
            
            var hash = this.$route.hash
            hash = hash.replace('#', '')
            var list = hash.split('&')
            var key = list[0].split('=')[0]
            var value = list[0].split('=')[1] / 1
            if(key == 'page') {
                if(! isNaN(value)) {
                    this.pageNo = parseInt(value) < 1 ? 1 : parseInt(value)
                }
            }
            this.keyword = this.$route.query.keyword || undefined
            this.arealevel1id = parseInt(this.$route.query.AreaLv1)
            this.arealevel2id = parseInt(this.$route.query.AreaLv2)
            this.filterValue = this.$route.query.IllCate
            this.onRefresh()
            this.getBookingCount()
        },
        created: function(){
            if(!this.categorys.length) {
                this.$store.dispatch('getAskCategorys', {'langcode': 'B5'})
                .then((rs)=>{
                    this.categorys = rs || []
                }) 
            }
        },
        methods: {
            onKeyEnter: function(key) {
                if(key.key === 'Enter') {
                    this.doSearch()
                }
            },
            doSearch: function(){
                this.pageNo = 1
                this.filterType = undefined
                this.filterValue = undefined
                this.visible = false
                var list = []
                if(this.area1Value) {
                    list.push(`AreaLv1=${this.area1Value}`)
                }
                if(this.area2Value) {
                    list.push(`AreaLv2=${this.area2Value}`)
                }
                if(this.categoryValue) {
                    this.filterType = 3
                    this.filterValue = this.categoryValue
                    list.push(`IllCate=${this.categoryValue}`)
                }
                if(this.keyword) {
                    list.push(`keyword=${this.keyword}`)
                }

                var path = list.length ? `${this.$route.path}?${list.join('&')}` : this.$route.path
                if(location.href.endsWith(path)) {
                    this.onRefresh()
                } else {
                    this.$router.push(path)
                }
            },
            onRefresh: function(){
                if(this.total > 0 && this.pageNo > 0) {
                    var maxPage = Math.floor((this.total + this.pageSize - 1) / this.pageSize)
                    if(maxPage > 0 && maxPage < this.pageNo) {
                        var path = this.$route.fullPath
                        var hash = this.$route.hash
                        if(hash && path.indexOf(hash) != -1) {
                            path = path.replace(hash, '')
                        }
                        this.$router.push({path: `${path}#page=1`},(e)=>{})
                        return
                    }
                }

                this.loading = true
                this.isSearch = true
                this.loadData({
                    arealevel1id: this.arealevel1id || undefined,
                    arealevel2id: this.arealevel2id || undefined,
                    keyword: this.keyword || undefined,
                    pageno: this.pageNo,
                    pagesize: this.pageSize,
                    sortdesc: this.categorySort[`${this.filterValue}`] ? 'ASC' : 'DESC',
                    sorttypeid: this.filterValue || undefined
                }, (data, error)=>{
                    this.isSearch = false
                    this.loading = false
                    if(error) {
                        this._toast.warning(this, error)
                    } else {
                        this.dataList = data.list
                        this.total = data.total
                        this.visible = this.total > 0
                        var maxPage = Math.floor((this.total + this.pageSize - 1) / this.pageSize)
                        if(maxPage > 0 && maxPage < this.pageNo) {
                            var path = this.$route.fullPath
                            var hash = this.$route.hash
                            if(hash && path.indexOf(hash) != -1) {
                                path = path.replace(hash, '')
                            }
                            this.$router.push({path: `${path}#page=1`},(e)=>{})
                            return
                        }
                    }
                })
            },
            onPageChanged: function(pageNo){
                var path = this.$route.fullPath
                var hash = this.$route.hash
                if(hash && path.indexOf(hash) != -1) {
                    path = path.replace(hash, '')
                }
                this.$router.push({path:  path+'#page='+pageNo},(e)=>{});
            },
            changeFilter: function(filterType, filterValue) {
                this.filterType = filterType
                this.filterValue = filterValue
                this.categorySort[`${filterValue}`] = ! this.categorySort[`${filterValue}`]
                this.pageNo = 1
                this.visible = false
                this.dataList = []
                this.onRefresh()
            },
            onBooingBtnClick: function(doctor){
                if(!this.$store.getters.clientId) {
                    this._toast.warning(this, '未登录')
                    setTimeout(() => {
                        this.$router.push('/login')
                    }, 1500)
                    return
                }
                this.bookingDoctor = doctor
                this.isBooking = true
                this.style = document.body.getAttribute('style') || ''
                document.body.setAttribute('style', this.style + 'overflow: hidden;')
            },
            onBookingCallback: function(flag) {
                this.isBooking = false
                document.body.setAttribute('style', this.style)
            },
            onArea1ItemClick: function(value, name) {
                this.area1Value = value
                this.area1Name = name || '選擇地區'
                this.area2Datas = []
                this.area2Value = null
                this.area2Name = '選擇地區'
                if(value) {
                    this.$store.dispatch('getAreaList', {'langcode': 'B5', 'parentId': value})
                    .then((data)=>{
                        this.area2Datas = data || []
                    })
                }
            },
            onArea2ItemClick: function(value, name) {
                this.area2Value = value
                this.area2Name = name || '選擇地區'
            },
            onCategoryItemClick: function(name, value) {
                this.categoryName = name
                this.categoryValue = value
            },
            getBookingCount: function() {
                this.$store.dispatch('getBookingCount', null)
                .then((data)=>{
                    this.bookingCount = data
                })
            }
        },
        watch: {
            '$route.query.IllCate': function(filterValue){
                if(this.isSearch) return
                this.pageNo = 1
                this.dataList = []
                this.filterValue = filterValue
                this.arealevel1id = this.$route.query.AreaLv1
                this.arealevel2id = this.$route.query.AreaLv2
            },
			'$route.query.AreaLv1': function(arealevel1id){
                if(this.isSearch) return
                this.pageNo = 1
                this.dataList = []
                this.visible = false
                this.arealevel1id = arealevel1id
                this.arealevel2id = this.$route.query.AreaLv2
                this.onRefresh()
            },
            '$route.query.AreaLv2': function(arealevel2id){
                if(this.isSearch) return
                this.pageNo = 1
                this.dataList = []
                this.visible = false
                this.arealevel1id = this.$route.query.AreaLv1
                this.arealevel2id = arealevel2id
                this.onRefresh()
            },
            $route(to,from){
                var hash = this.$route.hash
                if(hash) {
                    hash = hash.replace('#', '')
                    var list = hash.split('&')
                    var key = list[0].split('=')[0]
                    var value = list[0].split('=')[1] / 1
                    if(key == 'page') {
                        if(! isNaN(value)) {
                            this.pageNo = parseInt(value) < 1 ? 1 : parseInt(value)
                            this.visible = false
                        }
                    } else {
                        this.pageNo = 1
                    }
                } else {
                    this.pageNo = 1
                }
                this.keyword = this.$route.query.keyword || undefined
                this.arealevel1id = parseInt(this.$route.query.AreaLv1)
                this.arealevel2id = parseInt(this.$route.query.AreaLv2)
                this.filterValue = this.$route.query.IllCate
                if(! this.arealevel1id) {
                    this.area1Value = null
                    this.area1Name = '選擇地區'
                }
                if(! this.arealevel2id) {
                    this.area2Value = null
                    this.area2Name = '選擇地區'
                }
                if(!this.filterValue) {
                    this.filterType = 0
                    this.categoryValue = ''
                    this.categoryName = ''
                }
                this.onRefresh()
            }
		}
    }
</script>

<style lang="scss" scoped>
    #doctor-list {
        display: flex;
        flex-direction: column;
        position: relative;
    }

    .search-box-container {
        width: 100%;
        background-image: url(../../../assets/imgs/pc/img_dinbg2@2x.png);
        background-repeat: no-repeat;
        background-size: 100% 100%;
        display: flex;
        flex-direction: column;
    }

    .search-box-container > .booking-count {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        color: #3A5773;
        font-size: 18px;
        font-weight: 400;
        line-height: 21px;
        width: 1000px;
        margin: 36px auto 31px auto;
    }

    .search-box-container > .booking-count .value {
        color: #36C4D0;
        font-weight: bold;
        font-size: 24px;
        margin-left: 8px;
    }

    .search-box-container > .booking-count .value.booking {
        color: #FF8000;
        font-weight: bold;
        font-size: 24px;
        margin-right: 34px;
    }

    .search-box-container > .search-box {
        width: 1000px;
        height: 110px;
        margin: 30px auto;
        background-image: url(../../../assets/imgs/pc/img_slbg@2x.png);
        background-repeat: no-repeat;
        background-size: 100% 100%;
        display: flex;
        flex-direction: row;
    }

    .search-box-container > .search-box > .search-filter-c {
        display: flex;
        flex-direction: column;
        justify-content: center;
        position: relative;
        height: 110px;
        padding-right: 40px;
    }

    .search-box-container > .search-box > .search-filter-c::after {
        width: 1px;
        height: 60px;
        top: 27px;
        right: 0px;
        position: absolute;
        content: '';
        background-color: #fff;
    }

    .search-box-container > .search-box > .search-filter-c > .label {
        font-size: 18px;
        font-weight: 400;
        line-height: 18px;
        color: #fff;
        margin-left: 20px;
        display: inline-block;
    }

    .search-box-container > .search-box > .search-filter-c > .filter-c-value {
        width: 94px;
        height: 29px;
        margin-left: 20px;
        margin-top: 16px;
        background-color: #fff;
        line-height: 29px;
        padding-left: 12px;
        position: relative;
        cursor: pointer;
        color: #231F20;
        font-weight: bold;
        font-size: 16px;
    }
    .search-box-container > .search-box > .search-filter-c > .filter-c-value[data-value=''] {
        color: #696969;
        font-weight: 400;
    }

    .search-box-container > .search-box > .search-filter-c > .filter-c-value::before {
        right: 0;
        top: 0;
        height: 29px;
        width: 22px;
        background-color: #DCF7FB;
        content: '';
        position: absolute;
    }

    .search-box-container > .search-box > .search-filter-c > .filter-c-value::after {
        right: 6px;
        top: 10px;
        width: 10px;
        height: 7px;
        content: '';
        position: absolute;
        background-image: url(../../../assets/imgs/pc/img_xial@2x.png);
    }

    .search-box-container > .search-box > .search-filter-a {
        display: flex;
        flex-direction: column;
        justify-content: center;
        position: relative;
        height: 110px;
    }

    .search-box-container > .search-box > .search-filter-a > .label {
        font-size: 18px;
        font-weight: 400;
        line-height: 18px;
        color: #fff;
        margin-left: 40px;
        display: inline-block;
    }

    .search-box-container > .search-box > .search-filter-a > .filter-a-values {
        display: flex;
        flex-direction: row;
        padding-right: 40px;
    }

    .search-box-container > .search-box > .search-filter-a > .filter-a-values > .filter-a-v1,
    .search-box-container > .search-box > .search-filter-a > .filter-a-values > .filter-a-v2 {
        width: 110px;
        height: 29px;
        margin-left: 40px;
        margin-top: 16px;
        background-color: #fff;
        line-height: 29px;
        padding-left: 12px;
        position: relative;
        cursor: pointer;
        color: #231F20;
        font-weight: bold;
        font-size: 16px;
    }

    .search-box-container > .search-box > .search-filter-a > .filter-a-values > .filter-a-v1[data-value=''],
    .search-box-container > .search-box > .search-filter-a > .filter-a-values > .filter-a-v2[data-value=''] {
        color: #696969;
        font-weight: 400;
    }

    .search-box-container > .search-box > .search-filter-a > .filter-a-values > .filter-a-v2 {
        width: 108px;
        margin-left: 10px;
    }

    .search-box-container > .search-box > .search-filter-a > .filter-a-values > .filter-a-v1::before,
    .search-box-container > .search-box > .search-filter-a > .filter-a-values > .filter-a-v2::before {
        right: 0;
        top: 0;
        height: 29px;
        width: 22px;
        background-color: #DCF7FB;
        content: '';
        position: absolute;
    }
    .search-box-container > .search-box > .search-filter-a > .filter-a-values > .filter-a-v1::after,
    .search-box-container > .search-box > .search-filter-a > .filter-a-values > .filter-a-v2::after {
        right: 6px;
        top: 10px;
        width: 10px;
        height: 7px;
        content: '';
        position: absolute;
        background-image: url(../../../assets/imgs/pc/img_xial@2x.png);
    }

    .search-box-container > .search-box > .search-filter-a::after {
        width: 1px;
        height: 60px;
        top: 27px;
        right: 0px;
        position: absolute;
        content: '';
        background-color: #fff;
    }

    .search-box-container > .search-box > .search-filter-k {
        display: flex;
        flex-direction: column;
        justify-content: center;
        position: relative;
        height: 110px;
    }

    .search-box-container > .search-box > .search-filter-k  > .label {
        font-size: 18px;
        font-weight: 400;
        line-height: 18px;
        color: #fff;
        margin-left: 40px;
        display: inline-block;
    }

    .search-box-container > .search-box > .search-filter-k > .filter-k-value {
        width: 258px;
        height: 29px;
        margin-left: 40px;
        margin-top: 16px;
        background-color: #fff;
        line-height: 29px;
        padding-left: 12px;
        position: relative;
        color: #231F20;
        font-size: 16px;
        font-weight: bold;
    }

    .search-box-container > .search-box > .btn-search {
        display: inline-block;
        width: 80px;
        height: 30px;
        margin-left: 20px;  
        background-color: #DCF7FB;
        font-weight: bold;
        font-size: 18px;
        color: #36C4D0;
        line-height: 30px;
        text-align: center;
        cursor: pointer;
        margin-top: 56px;
    }

    .search-box-container > .search-box > .link-clinic {
        display: inline-block;
        height: 30px;
        font-weight: 400;
        font-size: 18px;
        line-height: 30px;
        text-align: center;
        text-decoration: underline;
        margin-top: 56px;
        color: #fff;
        margin-left: 20px;
    }

    .doctor-container {
        background-color: #fff;
    }

    .doctor-container  > .doctor-wrapper {
        width: 1000px;
        margin: 0px auto;
    }

    .doctor-container  > .doctor-wrapper > .doctor-filter-tags {
        display: flex;
        flex-direction: row;
        justify-content: space-evenly;
        padding-top: 40px;
        position: relative;
    }
    .doctor-container  > .doctor-wrapper > .doctor-filter-tags::after {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        height: 0.1px;
        border-bottom: 1px solid #DCF7FB;
    }

    .doctor-container  > .doctor-wrapper > .doctor-filter-tags > .doctor-filter-tag {
        display: inline-block;
        height: 42px;
        line-height: 42px;
        text-align: center;
        cursor: pointer;
        background-color: #fff;
        font-size: 16px;
        font-weight: 400;
        color: #242020;
        padding-right: 20px;
    }

    .doctor-container  > .doctor-wrapper > .doctor-filter-tags > .doctor-filter-tag.current {
        position: relative;
    }

    .doctor-container  > .doctor-wrapper > .doctor-filter-tags > .doctor-filter-tag.current::before {
        position: absolute;
        height: 7px;
        width: 10px;
        content: '';
        right: 8px;
        top: 50%;
        background-image: url(../../../assets/imgs/pc/img_cxial@2x.png);
        background-size: 100% 100%;
        background-repeat: no-repeat;
        transform: translateY(-50%);
    }

    .doctor-container  > .doctor-wrapper > .doctor-filter-tags > .doctor-filter-tag.current.filter-up::before {
        background-image: url(../../../assets/imgs/pc/img_cxial2@2x.png);
    }

    .doctor-container  > .doctor-wrapper > .doctor-filter-tags > .doctor-filter-tag.current {
        position: relative;
        color: #36C4D0;
        font-size: 18px;
        font-weight: bold;
    }

    .doctor-container  > .doctor-wrapper > .doctor-filter-tags > .doctor-filter-tag.current::after {
        position: absolute;
        height: 4px;
        width: 22px;
        content: '';
        left: 50%;
        bottom: 0;
        background-color: #36C4D0;
        transform: translateX(-19px);
    }

    .doctor-list {
        position: relative;
        padding-bottom: 110px;
        margin-top: 50px;
        display: grid;
        grid-template-columns: 245px 245px 245px;
        grid-template-rows: 80px;
        grid-row-gap: 50px;
        grid-column-gap: 134px;
    }
    
    .doctor-list > .doctor-item {
        display: flex;
        flex-direction: row;
    }

    .doctor-list > .doctor-item > .col {
        display: flex;
        flex-direction: column;
        margin-left: 16px;
        flex: 1;
    }

    .doctor-list > .doctor-item > .col > .row {
        display: flex;
        flex-direction: row;
    }

    .doctor-list > .doctor-item > .col > .row.address {
        flex: 1;
        align-items: center;
        justify-content: flex-start;
    }

    .doctor-list > .doctor-item:nth-child(3n+0) {
        margin-right: 0px;
    }

    .doctor-list > .doctor-item > .doctor-item-avatar {
        width: 70px;
        height: 76px;
        display: inline-block;
        background-size: 100% 100%;
        background-repeat: no-repeat;
        background-image: url(../../../assets/imgs/pc/img_nvys@2x.png);
    }

    .doctor-list > .doctor-item > .doctor-item-avatar.man {
        background-image: url(../../../assets/imgs/pc/img_nanys@2x.png);
    }

    .doctor-list > .doctor-item .doctor-item-name {
        flex: 1;
        text-align: left;
        font-size: 18px;
        line-height: 28px;
        font-weight: bold;
        color: #231F20;
    }

    .doctor-list > .doctor-item .doctor-item-address-list {
        text-overflow: ellipsis;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
    }

    .doctor-list > .doctor-item .doctor-item-address-list .doctor-item-address {
        font-size: 14px;
        font-weight: 400;
        color: #636363;
        margin-right: 9px;
        display: inline;
    }

    .doctor-list > .doctor-item .booking-count {
        color: #FF8000;
        font-size: 14px;
        font-weight: 400;
        display: none;
    }

    .doctor-list > .doctor-item .booking-count.show {
        display: block;
    }

    .doctor-list > .doctor-item .doctor-item-book {
        width: 64px;
        height: 28px;
        background-color: #FFE3D5;
        border-radius: 4px;
        line-height: 28px;
        text-align: center;
        cursor: pointer;
        color: #FF8000;
        font-size: 16px;
        font-weight: bold;
    }

    .doctor-list > .doctor-item > .doctor-item-book:hover {
        opacity: 0.9;
    }

    // 下拉菜单
    .ant-dropdown-menu {
        height: 168px;
        overflow-y: scroll;
    }
</style>
